import { init as initRemoteConfig } from '@web-solutions/core/store/remote-config/actions';
import { setLoaded, initAppLink, } from '@web-solutions/base-app/store/app/actions';
import { init as initRouting } from '@web-solutions/base-app/store/routing/actions';
import { init as initAccountManagment } from '@web-solutions/account-management/store/actions'
import { auth } from '@web-solutions/base-app/store/profile/actions';

import { applyTheme } from '@web-solutions/core/utils/theme';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { getMagnusToken } from './utils';

/**
 * Initialization RemoteConfig only
 */

export const loadRemoteConfig = () => async (dispatch, getState) => {
  try {
    await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG));
    const state = getState();
    const theme = state?.remoteConfig?.theme;
    if (theme) {
      applyTheme(theme)
    }
  } catch (error) {
    console.warn('[ERROR INIT REMOTE CONFIG]', error);
  } finally {
    dispatch(setLoaded(true));
  }
};

export const load = () => async (dispatch, getState) => {
  try {
    dispatch(auth());
    await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG));
    const state = getState();
    const theme = state?.remoteConfig?.theme;
    if (theme) {
      applyTheme(theme)
    }

    await dispatch(initAccountManagment());
    
    const isSkipFirebase = window.location.pathname === '/re-auth'
    if (!isSkipFirebase) {
      await dispatch(initRouting());
    }

    await dispatch(initAppLink());
  } catch (error) {
    console.warn('[ERROR INIT APP]', error);
  } finally {
    dispatch(setLoaded(true));
  }
};