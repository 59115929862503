export const DARK_FORM_STYLES = {}

export const SUBMIT_BUTTON_COLOR = '#8652FF';
export const SUBMIT_BUTTON_COLOR_LIGHT_THEME = '#181B26';
export const SUBMIT_BUTTON_COLOR_DARK_THEME = '';
export const SUBMIT_BUTTON_COLOR_MONOCHROME = '';

export const SUBMIT_BUTTON_BORDER_RADIUS_MONOCHROME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS_LIGHT_THEME = '8px';
export const SUBMIT_BUTTON_BORDER_RADIUS_DARK_THEME = '';
export const SUBMIT_BUTTON_BORDER_RADIUS = '';
